import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations, getThemeName } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import './ThemeSelection.css'; 
import DataContext from '../Reusables/DataContext';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';

const ThemeSelection = () => {
  let navigate = useNavigate();
  const { themes, fetchThemes, setTopics, topics, setSentencesForStudy } = useContext(DataContext);
  const { updateTheme, updateEnglishTheme } = useUserAnswers();
  const { selectedLanguage } = useLanguage();
  const [themeClick, setThemeClick] = useState(false);

  useEffect(() => {
    fetchThemes();    
  }, []);

  useEffect(() => {    
    if (themeClick && topics.length === 0){
      setThemeClick(false);
      navigate('/topic-selection');
    }
  }, [themeClick]);

  const handleThemeClick = (themeName, themeNameEn) => {
    setTopics([]);
    setSentencesForStudy([]);
    updateTheme(themeName);
    updateEnglishTheme(themeNameEn);
    setThemeClick(true); 
  };

  const colors = ['#DBF5FB', '#FFF4DF', '#F9E6EC', '#E9E6F9'];
  
  return (
    <div className="theme-selection-container">
      <div className="top-row">
      <button onClick={() => navigate('/')} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
      <h3>{translations[selectedLanguage].chooseATheme}</h3>
        <div><BurgerMenuComponent /></div>          
      </div>   
      <div className="themes-grid">      
        {themes.map((theme, index) => (
          <div key={index} className="theme-card" style={{ backgroundColor: colors[index % colors.length] }} onClick={() => handleThemeClick(getThemeName(theme, selectedLanguage), getThemeName(theme, 'en'))}>            
            <img 
              src={theme.imgsrc} 
              alt=""
              className="theme-image" 
              // className={`theme-image ${theme.name !== 'Travel' ? 'inactive' : ''}`} 
            />           
            <button className="theme-name" style={{ backgroundColor: colors[index % colors.length] }}>
              {getThemeName(theme, selectedLanguage)}
              {/* {theme.name !== 'Travel' && <span className="lock-icon">&#128274;</span>} */}
            </button>            
          </div>
        ))} 
      </div>   
    </div>
  );
};

export default ThemeSelection;