import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginScreen from './MainPages/Home'; 
import TopicSelection from './Topic/TopicSelection';
import ThemeSelection from './Topic/ThemeSelection';
import NoviceProgram from './Topic/NoviceProgram';
import SpeakingSentences from './Learn/SpeakingSentences';  
import SummaryPage from './Learn/SummaryPage';
import Practice from './Practice/Practice';   
import PracticeSpeaking from './Practice/PracticeSpeaking';  
import PracticeWriting from './Practice/PracticeWriting';  
import CompletionPage from './Completion/CompletionPage';  
import PronunciationReport from './Completion/PronunciationReport';  
import GrammarReport from './Completion/GrammarReport'; 
import ReviseConversation from './Completion/ReviseConversation';  
import Profile from './MainPages/Profile'; 
import MyVocabularies from './StudyMenu/MyVocabularies'; 
import GrammarGroups from './StudyMenu/GrammarGroups'; 
import GrammarContent from './StudyMenu/GrammarContent'; 
import PronunciationGroups from './StudyMenu/PronunciationGroups'; 
import PronunciationContent from './StudyMenu/PronunciationContent'; 
import ScrollToTop  from './Reusables/ScrollToTop';
import './App.css';

const App = () => {
  return (
    <div style={{ background: '#FFFFFF' }}>        
    <Router>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/novice-program" element={<NoviceProgram />} />
        <Route path="/theme-selection" element={<ThemeSelection />} />
        <Route path="/topic-selection" element={<TopicSelection />} />
        <Route path="/speaking-sentences" element={<SpeakingSentences />} />
        <Route path="/summary-page" element={<SummaryPage />} />
        <Route path="/practice" element={<Practice />} />
        <Route path="/practice-speaking" element={<PracticeSpeaking />} />
        <Route path="/practice-writing" element={<PracticeWriting />} />
        <Route path="/completion-page" element={<CompletionPage />} />
        <Route path="/pronunciation-report" element={<PronunciationReport />} />   
        <Route path="/grammar-report" element={<GrammarReport />} />  
        <Route path="/revise-conversation" element={<ReviseConversation />} />  
        <Route path="/profile" element={<Profile />} />
        <Route path="/my-vocabulary" element={<MyVocabularies />} /> 
        <Route path="/grammar" element={<GrammarGroups />} /> 
        <Route path="/grammar-topic" element={<GrammarContent />} />
        <Route path="/pronunciation" element={<PronunciationGroups />} /> 
        <Route path="/pronunciation-topic" element={<PronunciationContent />} />
      </Routes>
    </Router>    
    </div>
  );
};

export default App;