import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FavoritesVocabProvider } from './StudyMenu/FavoriteVocab';
// import { UserSpeakingAnswersProvider } from './Practice/UserSpeakingAnswers'
// import { UserWritingAnswersProvider } from './Practice/UserWritingAnswers'
import { UserAnswersProvider } from './Practice/UserAnswers'
import { PlayAudioProvider } from './Reusables/PlayAudio';
import { RecordUserProvider } from './Reusables/RecordUser';
import { ClickWordProvider } from './Reusables/ClickWord';
import { DataProvider } from './Reusables/DataContext';
import { LanguageProvider } from './Reusables/LanguageProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FavoritesVocabProvider> 
    {/* <UserSpeakingAnswersProvider> */}
    <UserAnswersProvider>
    <PlayAudioProvider>
    <RecordUserProvider>
    <DataProvider>
    <ClickWordProvider>  
    <LanguageProvider>
      <App /> 
    </LanguageProvider>            
    </ClickWordProvider>
    </DataProvider>      
    </RecordUserProvider>
    </PlayAudioProvider>     
    </UserAnswersProvider>
    {/* </UserSpeakingAnswersProvider> */}
    </FavoritesVocabProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
