import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations, getThemeName } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import './ThemeSelection.css'; 
import DataContext from '../Reusables/DataContext';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';

const NoviceProgram = () => {  
  const navigate = useNavigate();
  const { noviceThemes, fetchNoviceThemes, sentencesForStudy, setSentencesForStudy  } = useContext(DataContext);
  const { updateEnglishTheme, updateTopic, updateEnglishTopic } = useUserAnswers();
  const [clickTheme, setClickTheme] = useState(false);
  const { selectedLanguage } = useLanguage();
  
  useEffect(() => {
    fetchNoviceThemes();    
  }, []);

  useEffect(() => {    
    if (clickTheme && sentencesForStudy.length === 0){
      navigate('/speaking-sentences', { state: { index: 0, fromSummary: false } });
    }
  }, [clickTheme]);

  const handleThemeClick = (themeName, themeNameEn) => {
    setSentencesForStudy([]);
    updateEnglishTheme('NoviceFR');        // to match audio structure
    updateTopic(themeName);         // novice_sentences are linked to theme_id, but in here, we match them to topic
    updateEnglishTopic(themeNameEn);
    setClickTheme(true);
  };
  
  return (
    // LOOKS LIKE IN THEME SELECTION PAGE
    // <div className="theme-selection-container">
    //   <div className="top-row">
    //     <button onClick={() => navigate('/')} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
    //     <h3>{translations[selectedLanguage].chooseATheme}</h3>
    //     <div><BurgerMenuComponent /></div>          
    //   </div>   
    //   <div className="themes-grid-novice">      
    //     {noviceThemes.map((theme, index) => (
    //       <div key={index} className="theme-card-novice" style={{ borderColor: '#F9E6EC'}} onClick={() => handleThemeClick(getThemeName(theme, selectedLanguage), getThemeName(theme, 'en'))}>                                 
    //         <button className="theme-name" >
    //           {getThemeName(theme, selectedLanguage)}
    //         </button>            
    //       </div>
    //     ))} 
    //   </div>   
    // </div>

    <div className="topic-container">
       <div className="top-row">
         <button onClick={() => navigate('/')} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].chooseATheme}</h3>
         <div><BurgerMenuComponent /></div>          
       </div>
    
      <div>
        {noviceThemes.map((topic, index) => (
            <div key={index}  className="topics">
            <button className="topic-button" onClick={() => handleThemeClick(getThemeName(topic, selectedLanguage), getThemeName(topic, 'en'))} >
              <div style={{marginRight:"auto"}}>{getThemeName(topic, selectedLanguage)} </div>
              <div style={{marginLeft:"auto"}}><img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} /></div>
            </button>  

            {index < noviceThemes.length - 1 && <hr />}
            </div>            
        ))}
      </div> 
    </div> 
  );
 };

export default NoviceProgram;

