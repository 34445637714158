import React from 'react';
import { translations } from './Utilities';
import { useLanguage } from './LanguageProvider';
import { useNavigate } from 'react-router-dom';
import FlagComponent from './FlagComponent';
import HelpComponent from './HelpComponent';
import { useRecordUser } from './RecordUser';
import { useUserAnswers } from '../Practice/UserAnswers';
import './Reusables.css'; 

const BottomMenuComponent = React.memo(({ steps }) => {
    const { selectedLanguage } = useLanguage();
    const navigate = useNavigate();
    const {closeAudio, setTranscription} = useRecordUser();  
    const { program} = useUserAnswers();

  const goToStudy = () => {  
    setTranscription('');    
    navigate('/speaking-sentences', { state: { index: 0, fromSummary: false } });
  };

  const goToSummary = () => {      
    closeAudio();      
    navigate('/summary-page');
  };

  const goToPractice = () => { 
    closeAudio();
    setTranscription(''); 
    if (program !== 'novice'){
      navigate('/practice');
    }         
  };

  return (
    <div className="bottom-menu">
      <button className="menu-icon" onClick={goToStudy}>
        <img style={{width:'30px', height:'30px'}}  src="/Icons/study-bottome-menu.svg" alt={translations[selectedLanguage].study} />
      </button>
      <button className="menu-icon" onClick={goToSummary}>
        <img style={{width:'25px', height:'25px'}}  src="/Icons/summary-bottome-menu.svg" alt={translations[selectedLanguage].summary} />
      </button>
      <button className="menu-icon" onClick={goToPractice}>
        <img style={{width:'25px', height:'25px'}}  src="/Icons/practice-bottom-menu.svg" alt={translations[selectedLanguage].practice} />
      </button>
      <HelpComponent steps={steps} />       
      <FlagComponent/>        
    </div>
  );
});

export default BottomMenuComponent;
