import React, { useEffect, useRef } from 'react';
import {translations } from './Utilities';
import { useLanguage } from './LanguageProvider';
import './Reusables.css'; // Ensure to import the CSS file

const AnswerInput = ({ answer, onAnswerChange, onAnswerSubmit }) => {
    const textareaRef = useRef(null);
    const { selectedLanguage } = useLanguage();

    const adjustHeight = () => {        
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto'; // Reset the height
            const newHeight = Math.min(textarea.scrollHeight, 150); // Set a max height
            textarea.style.height = newHeight + 'px'; // Apply new height with max limit
        }
    };

    // Adjust height whenever the 'answer' changes
    useEffect(() => {
        adjustHeight();
    }, [answer]);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submit behavior
        onAnswerSubmit(); // Call the submit handler passed down from App
    };

    // enter to submit; shift + enter to create a new line
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent the default behavior of inserting a new line
            handleSubmit(event); // Submit the form
        } else  {
            adjustHeight(); // Adjust the height in case of other keys
        }
    };

    return (
        <form onSubmit={handleSubmit} className="answer-form">       
            <textarea
                ref={textareaRef}
                value={answer}
                onChange={(e) => {
                    onAnswerChange(e.target.value);
                    adjustHeight();
                }}
                onKeyDown={handleKeyDown}
                placeholder= {translations[selectedLanguage].TypeAnswerHere}
                className="answer-input"
                rows="1" // Starts with a single line
            />
            <button type="submit" className="practice-write-button">
                <img src="Icons/submit.svg" alt={translations[selectedLanguage].Submit} />
            </button>           
        </form>
    );
};

export default AnswerInput;