import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import DataContext from '../Reusables/DataContext';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';

const ReviseConversation = () => {
  const { conversations } = useContext(DataContext);
  let navigate = useNavigate();
  const {theme, topic} = useUserAnswers();
  const [pairs, setPairs] = useState([]);
  const { selectedLanguage } = useLanguage();

  useEffect(() => {
    if (conversations && conversations.length > 0) {
      setPairs(conversations[0].pairs);
    } else {
      // navigate('/');
    }
  }, [conversations]);

  return (
    <div className="study-page">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].ReviseThisConversation}</h3>
        <BurgerMenuComponent />  
      </div>
      <div className="study-body">  
        <ul className="completion-details print-section">
          <li><b>{translations[selectedLanguage].Theme}:</b> {theme}</li>             
          <li><b>{translations[selectedLanguage].Topic}:</b> {topic}</li>
        </ul>
        <br/>
        <div className="completion-details print-section">
          {pairs.map((pair, index) => (
            <div key={index}>
              <p><strong>A: </strong>{pair.application ? pair.application.fr : 'No application available'}</p>
              <div style={{marginLeft:'30px'}}>
                {pair.suggestResponses && pair.suggestResponses.length > 0 ? (
                  pair.suggestResponses.slice(0,3).map((response, idx) => (
                    <p key={idx}><strong>B{idx + 1}: </strong>{response.fr}</p>
                  ))
                ) : (
                  <p>No suggestions available</p>
                )}
              </div>
              <br />
            </div>
          ))}
        </div>
        <br/><br/><br/>
      </div>  
      <BottomMenuComponent steps={[]}/>        
    </div>
  );
};

export default ReviseConversation; 
