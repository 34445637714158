import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { translations, getInstructionVideo } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useUserAnswers  } from '../Practice/UserAnswers';
import './Home.css'; 

const Home = () => {
  let navigate = useNavigate();
  const { selectedLanguage, updateSelectedLanguage } = useLanguage(); 
  const {program, updateProgram} = useUserAnswers();

  const programs = [
    { code: 'novice', name: translations[selectedLanguage].NoviceProgram}, 
    // { code: 'generalEasy', name: translations[selectedLanguage].GeneralProgramEasy },   
    { code: 'general', name: translations[selectedLanguage].GeneralProgram }
  ];

  const handleProgramChange = (e) => {
    updateProgram(e.target.value);
  };
  
  const languages = [
    { code: 'en', name: 'English'}, 
    { code: 'es', name: 'Español' },   // Spanish
    { code: 'pt', name: 'Português' }, // Portuguese
    { code: 'ar', name: 'العربية' },   // Arabic
    { code: 'fa', name: 'فارسی' },     // Persian (Farsi)
    { code: 'zh', name: '中文' },       // Simplified Chinese
    { code: 'vi', name: 'Tiếng Việt' } // Vietnamese
  ];

  const handleLanguageChange = (e) => {
    updateSelectedLanguage(e.target.value);
    console.log("Language selected:", e.target.value);
  };

  const handleStart = () => {
    switch(program){
      case 'novice':
        navigate('/novice-program');
        break;
      case 'general':
          navigate('/theme-selection');
          break;
      default:
    }   
  };

  /********** instruction video **********/  
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoInstructionClick = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <div className="start-container">
      <div className="logo">        
        <img src="logo-header-center.png" alt={translations[selectedLanguage].Logo} />
      </div>
      
      <div className="language-selector">
        <select value={selectedLanguage} onChange={handleLanguageChange}>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>
              {language.name}
            </option>
          ))}
        </select>
      </div>

      <div className="program-selector">
        <select value={program} onChange={handleProgramChange}>
          {programs.map((program) => (
            <option key={program.code} value={program.code}>
              {program.name}
            </option>
          ))}
        </select>
      </div>

      <button className="instruction-link" onClick={handleVideoInstructionClick}>{translations[selectedLanguage].InstructionVideo}</button> 
      {showVideo && (
        <div className="video-popup-overlay">
          <button className="video-close-button" onClick={handleCloseVideo}>X</button>
          <video controls className="responsive-video">
            <source src={getInstructionVideo(selectedLanguage)} type="video/mp4" />
            {translations[selectedLanguage].Doesnotsupportvideo}
          </video>
        </div>
      )}

      <button className="start-button" onClick={handleStart}>
        <b>{translations[selectedLanguage].start} </b>
      </button>
    </div>
  );
};

export default Home;
