
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './CompletionPage.css'; 

const CompletionPage = () => {
  let navigate = useNavigate();
  const { theme, topic} = useUserAnswers();
  const { selectedLanguage } = useLanguage();

  return (
    <div className="study-page">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].BravoYouFinished}</h3>
        <BurgerMenuComponent />  
      </div>
      <div className="completion-page-content">
        <ul className="completion-details">
          <li><b>{translations[selectedLanguage].Theme}:</b> {theme}</li>             
          <li><b>{translations[selectedLanguage].Topic}:</b> {topic}</li>
        </ul>

        <div className="practice-options">
          <button style={{margin:'50px auto 0px auto'}} className="report-button" onClick={() => navigate('/practice')}>
            {translations[selectedLanguage].PracticeAgain}
            <img style={{width:'25px', height:'25px', marginLeft:'auto'}} src="Icons/refresh.svg" alt="" /></button>                  
          <button className="report-button" onClick={() => navigate('/topic-selection')}>
            {translations[selectedLanguage].ChooseAnotherTopic}
            <img style={{width:'25px', height:'25px', marginLeft:'auto'}} src="Icons/another.svg" alt="" /></button>
          <button className="report-button" onClick={() => navigate('/revise-conversation')}>
            {translations[selectedLanguage].ReviseThisConversation}
            <img style={{width:'22px', height:'25px', marginLeft:'auto'}} src="Icons/transcription-practice.svg" alt="" /></button> 
          <button className="report-button" onClick={() => navigate('/pronunciation-report')}>
            {translations[selectedLanguage].PronunciationReport}
            <img style={{width:'22px', height:'22px', marginLeft:'auto'}} src={"/Icons/practice.svg"} alt="" /> </button>
          <button className="report-button" onClick={() => navigate('/grammar-report')}>
            {translations[selectedLanguage].GrammarReport}
            <img style={{width:'20px', height:'20px', marginLeft:'auto'}} src={"/Icons/pencil.svg"} alt="" className="arrow-right" /> </button> 
        </div>
        <br/><br/><br/>
      </div>
      <BottomMenuComponent steps={[]}/>   
    </div>
  );
};

export default CompletionPage;