// LanguageProvider.js
import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a LanguageContext
const LanguageContext = createContext();

// Create a provider component
export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem('selectedLanguage') || 'en'; // Load from localStorage
  });

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);

  const updateSelectedLanguage = (newSelectedLanguage) => setSelectedLanguage(newSelectedLanguage);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, updateSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Create a custom hook for easy access to the context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  
  return context;
};
